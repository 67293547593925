export const getWorkStyles = ({ smallWorkHeight, bigWorkHeight, fullWorkHeight }) => ({
  work: {
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
    display: 'block',
    color: 'inherit !important',
    '&:hover': {
      '& .hoverHidden': {
        opacity: 0.7,
      },
      '& .hoverScale': {
        transform: 'scale(1.05)',
      },
    },
  },
  hovered: {
    '& .hoverHidden': {
      opacity: 0.7,
    },
    '& .hoverScale': {
      transform: 'scale(1.05)',
    },
  },
  workInformation: {
    position: 'absolute',
    top: smallWorkHeight * 0.2,
    zIndex: 5,
    left: 32,
    right: 32,
  },
  logo: {
    height: 17,
    paddingBottom: 27,
  },
  name: {
    display: 'block',
    textTransform: 'uppercase',
    transition: 'opacity .4s',
    lineHeight: 1.43,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 44,
    transform: 'translateZ(2px)',
  },
  defaultName: {
    height: ({ nameHeight }) => nameHeight,
    fontSize: 30,
    opacity: ({ isDefaultView }) => isDefaultView ? 1 : 0,
  },
  shrinkName: {
    height: ({ shrinkNameHeight }) => shrinkNameHeight,
    fontSize: 15,
    opacity: ({ isDefaultView }) => isDefaultView ? 0 : 1,
  },
  description: {
    height: ({ descriptionHeight }) => descriptionHeight,
    fontSize: 15,
    lineHeight: 1.5,
    color: 'rgba(255, 255, 255, 0.8)',
    // textTransform: 'uppercase',
    transition: 'opacity .4s',
    opacity: ({ isDefaultView }) => isDefaultView ? 1 : 0,
    transform: ({ nameHeight }) => `translateY(${nameHeight}px)`,
  },
  descriptionText: {
    paddingTop: 28,
    boxSizing: 'border-box',
  },
  subtitle: {
    paddingTop: 16,
    fontSize: 15,
    lineHeight: '1.43',
    letterSpacing: 'normal',
    color: 'rgba(255, 255, 255, 0.5)',
    textTransform: 'uppercase',
    transition: 'transform .4s',
    marginTop: ({ shrinkNameHeight, isMainWork, nameHeight }) => isMainWork ? nameHeight : shrinkNameHeight,
    transform: ({ isDefaultView, descriptionHeight, nameHeight, isMainWork, shrinkNameHeight }) => {
      if (isMainWork) {
        return isDefaultView ? 'translateY(0)' : `translateY(-${descriptionHeight + nameHeight - shrinkNameHeight}px)`;
      }
      return null;
    },
  },
  workMask: ({ maskStyle }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
    height: '105%',
    width: '105%',
    pointerEvents: 'none',
    transition: 'opacity .4s',
    transform: 'translate3d(-1.5px, -1.5px, 1px)',
    ...maskStyle,
  }),
  workImageContainer: ({ extraWorksCount }) => ({
    width: smallWorkHeight,
    height: extraWorksCount === 1 ? bigWorkHeight : smallWorkHeight,
    transition: 'transform 0.2s ease',
  }),
  workImage: ({ imageStyle, isMainWork }) => ({
    display: 'block',
    height: isMainWork ? fullWorkHeight : smallWorkHeight,
    transition: 'transform .4s',
    transformOrigin: 'left top',
    willChange: 'transform',
    transform: 'translateZ(0)',
    ...imageStyle,
  }),
});