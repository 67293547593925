import { useMemo } from 'react';
import { BestDocumentHeight, WorkPadding, DirectoryMinWidth } from '@/constants';
import { usePageContext } from '@/context';

export const useStaticTheme = () => {
  const workWidth = (BestDocumentHeight - 2 * WorkPadding) / 3;
  const smallWorkHeight = workWidth;
  const bigWorkHeight = smallWorkHeight * 2 + WorkPadding;
  const directoryWidth = Math.max(DirectoryMinWidth, workWidth * .75);
  const { mainWorks } = usePageContext();

  const fullWidth = useMemo(() => {
    return directoryWidth + mainWorks.length * workWidth + (mainWorks.length - 1) * WorkPadding;
  }, [directoryWidth, workWidth, mainWorks]);

  return useMemo(() => ({
    documentHeight: BestDocumentHeight,
    workWidth,
    smallWorkHeight,
    bigWorkHeight,
    fullWorkHeight: BestDocumentHeight,
    workPadding: WorkPadding,
    directoryWidth,
    fullWidth,
  }), [bigWorkHeight, smallWorkHeight, workWidth, fullWidth, directoryWidth]);
};
