import React from 'react';
import ReactDOM from 'react-dom';
import Promise from 'bluebird';
import { App } from '@/app';

import '@/elements';

Promise.config({
  cancellation: true,
});

window.PageConfig.PageTitle = process.env.REACT_APP_PAGE_TITLE;

ReactDOM.render(
  <App />,
  document.getElementById('root'));
