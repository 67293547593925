import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDefaultStyles, useGroupedWorks } from '@/hooks';
import { getSwiperPanelStyles } from './styles';
import { Column } from './column';

export const SwiperPanel = ({ className }) => {
  const classes = useDefaultStyles(getSwiperPanelStyles);
  const groupedWorks = useGroupedWorks();

  return (
    <div className={classnames(className, classes.swiperPanel)}>
      <div className="swiper-wrapper">
        {
          groupedWorks.map(({ work, ...rest }, index) => <Column key={work.alias} work={work} columnIndex={index} {...rest} />)
        }
      </div>
    </div>
  );
};

SwiperPanel.propTypes = {
  className: PropTypes.string,
};

SwiperPanel.defaultProps = {
  className: null,
};
