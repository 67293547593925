import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { usePageContext, useViewModeContext } from '@/context';
import i18n from '@/i18n';
import { SimpleNameI18nSuffix } from '@/constants';
import { useDefaultStyles } from '@/hooks';
import { SubDirectory } from './sub-directory';
import { getWorkListStyles } from './styles';

export const WorkList = ({
  onWorkClick,
  onWorkMouseEnter,
  onWorkMouseLeave,
}) => {
  const { mainWorks, works } = usePageContext();
  const { isDefaultView, toShrinkView, toDefaultView } = useViewModeContext();
  const classes = useDefaultStyles(getWorkListStyles);
  const items = isDefaultView ? mainWorks : works;

  return mainWorks && (
    <SubDirectory
      items={items}
      onItemClick={onWorkClick}
      onItemMouseEnter={onWorkMouseEnter}
      onItemMouseLeave={onWorkMouseLeave}
      keyGenerator={work => work.alias}
      textGenerator={work => i18n.getString(work.name + SimpleNameI18nSuffix)}
      suffix={[{
        id: 'changeViewMode',
        className: classes.suffix,
        item: <span className={classnames(classes.changeViewMode, 'hoverWhite')}>{i18n.getString(isDefaultView ? 'ShowMore' : 'ShowLess')}</span>,
        onClick: () => isDefaultView ? toShrinkView() : toDefaultView(),
      }]}
    />
  );
};

WorkList.propTypes = {
  onWorkClick: PropTypes.func.isRequired,
  onWorkMouseEnter: PropTypes.func.isRequired,
  onWorkMouseLeave: PropTypes.func.isRequired,
};
