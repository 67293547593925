import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'underscore';
import { useDefaultStyles } from '@/hooks';
import { getFooterStyles } from './styles';
import i18n from '@/i18n';
import { usePageContext, useViewModeContext, useRouterContext } from '@/context';
import { SimpleNameI18nSuffix } from '@/constants';

export const Footer = ({ work }) => {
  const classes = useDefaultStyles(getFooterStyles);
  const { mainWorks, works } = usePageContext();
  const { isDefaultView } = useViewModeContext();
  const { toWorkPage, toMainPage } = useRouterContext();

  const workList = isDefaultView ? mainWorks : works;
  const currentIndex = _.findIndex(workList, work);

  const previousWork = workList[(currentIndex + workList.length - 1) % workList.length];
  const nextWork = workList[(currentIndex + workList.length + 1) % workList.length];

  const toPreviousWork = () => {
    toMainPage();
    setTimeout(() => toWorkPage(previousWork), 700);
  };

  const toNextWork = () => {
    toMainPage();
    setTimeout(() => toWorkPage(nextWork), 700);
  };

  return (
    <div className={classes.container}>
      <div className={classnames(classes.nav, classes.leftNav, classes.borderRight)} onClick={toPreviousWork}>
        <div className={classes.leftNavContent}>
          <span className={classnames(classes.leftIcon, 'iconfont icon-back colorWhite')}></span>
          <div className={classnames(classes.navContent, 'colorWhite')}>
            <div className={classes.guide}>{i18n.getString('PreviousProject')}</div>
            <div className={classes.name}>{i18n.getString(previousWork.name + SimpleNameI18nSuffix)}</div>
          </div>
        </div>
      </div>
      <div className={classnames(classes.nav, classes.rightNav)} onClick={toNextWork}>
        <div className={classes.rightNavContent}>
          <div className={classnames(classes.navContent, 'colorWhite')}>
            <div className={classes.guide}>{i18n.getString('NextProject')}</div>
            <div className={classes.name}>{i18n.getString(nextWork.name + SimpleNameI18nSuffix)}</div>
          </div>
          <span className={classnames(classes.rightIcon, 'iconfont icon-next colorWhite')}></span>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  work: PropTypes.shape({}).isRequired,
};
