import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'underscore';
import { useDefaultStyles } from '@/hooks';
import { getSubDirectoryStyles } from './styles';

export const SubDirectory = ({
  containerClassName,
  itemClassName,
  items,
  onItemClick,
  onItemMouseEnter,
  onItemMouseLeave,
  extraDataGenerator,
  keyGenerator,
  textGenerator,
  suffix,
}) => {
  const classes = useDefaultStyles(getSubDirectoryStyles);

  return (
    <div className={containerClassName}>
      {
        items.map((item, index) => (
          <button
            key={keyGenerator(item, index)}
            className={classnames(classes.item, itemClassName)}
            onClick={e => onItemClick(item, e)}
            onMouseEnter={e => onItemMouseEnter(item, e)}
            onMouseLeave={e => onItemMouseLeave(item, e)}
            {...extraDataGenerator(item, index)}
          >
            {textGenerator(item, index)}
          </button>
        ))
      }
      {
        suffix.map(({ id, item, onClick, className }) => (
          <button
            key={id}
            className={classnames(classes.item, itemClassName, className)}
            onClick={onClick}
          >
            {item}
          </button>)
        )
      }
    </div>
  );
};

SubDirectory.propTypes = {
  containerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onItemClick: PropTypes.func.isRequired,
  keyGenerator: PropTypes.func.isRequired,
  textGenerator: PropTypes.func.isRequired,
  extraDataGenerator: PropTypes.func,
  onItemMouseEnter: PropTypes.func,
  onItemMouseLeave: PropTypes.func,
  suffix: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    item: PropTypes.node,
    onClick: PropTypes.func,
  })),
};

SubDirectory.defaultProps = {
  containerClassName: null,
  extraDataGenerator: _.noop,
  itemClassName: null,
  suffix: [],
  onItemMouseEnter: _.noop,
  onItemMouseLeave: _.noop,
};
