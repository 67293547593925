import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDefaultStyles } from '@/hooks';
import { useViewModeContext } from '@/context';
import { getColumnStyles } from './styles';
import { Work } from './work';

export const Column = ({
  columnIndex,
  aboveWork,
  work,
  belowWork,
  columnWorks,
}) => {
  const hasAboveWork = aboveWork.length > 0;
  const hasBelowWork = belowWork.length > 0;
  const extraWorksCount = columnWorks.length - 1;
  const isMainWorkAtTop = !hasAboveWork;
  const isMainWorkAtMiddle = hasAboveWork && hasBelowWork;
  const isMainWorkAtBottom = !hasBelowWork;

  const { isDefaultView } = useViewModeContext();
  const classes = useDefaultStyles(getColumnStyles, {
    extraWorksCount,
    isDefaultView,
  });

  const columnClassName = classnames({
    [classes.columnWrapper]: true,
    [classes.isMainWorkAtBottom]: isMainWorkAtBottom,
    [classes.isMainWorkAtMiddle]: isMainWorkAtMiddle,
    [classes.isMainWorkAtTop]: isMainWorkAtTop,
  });

  // eslint-disable-next-line react/display-name
  const renderExtraWork = isAbove => w => (
    <Work key={w.alias} work={w} className={classnames(classes.smallSize, isAbove ? 'isAboveWork' : 'isBelowWork')} />
  );

  return (
    <div className={classnames('swiper-slide', classes.swiperSlide, `column-${columnIndex}`)}>
      <div className={columnClassName}>
        {
          aboveWork.map(renderExtraWork(true))
        }
        <Work
          work={work}
          className={classes.fullSize}
          extraWorksCount={extraWorksCount}
          isMainWorkAtTop={isMainWorkAtTop}
          isMainWorkAtMiddle={isMainWorkAtMiddle}
          isMainWorkAtBottom={isMainWorkAtBottom}
          isMainWork
        />
        {
          belowWork.map(renderExtraWork(false))
        }
      </div>
    </div>
  );
};

Column.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  aboveWork: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  work: PropTypes.shape({
    alias: PropTypes.string,
  }).isRequired,
  belowWork: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columnWorks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
