export const getDirectoryItemStyles = () => ({
  titleContainer: {
    borderBottom: '0.5px solid rgba(255, 255, 255, 0.3)',
    padding: '5px 0',
    textTransform: 'uppercase',
    fontFamily: 'Ruda-Bold',
  },
  title: {
    color: ({ disabled }) => !disabled ? '#fff !important' : 'rgba(255, 255, 255, 0.4)',
    cursor: ({ clickable }) => clickable ? 'pointer' : 'auto',
  },
  suffix: {
    color: '#fff',
    '&:before': {
      content: '">"',
      padding: [0, 4],
    },
  },
  subDirectoryContainer: {
    padding: '14px 0 0 19px',
  },
});
