/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect, useRef } from 'react';
import Promise from 'bluebird';

export const useRequestCallback = (requestGenerator, dependencies = []) => {
  const [state, setState] = useState({
    data: null,
    error: null,
    isLoading: false,
  });

  const abortController = useRef(null);
  const abortFetch = () => abortController.current && abortController.current.abort();

  const abortableFetchGenerator = useCallback((url, opt = {}) => {
    abortController.current = new AbortController();
    return Promise.resolve(fetch(url, {
      ...opt,
      signal: abortController.current.signal,
    })).then(data => data.text());
  }, dependencies);
  
  const execution = useCallback(() => {
    abortFetch();

    setState({
      data: null,
      error: null,
      isLoading: true,
    });

    const promise = requestGenerator(abortableFetchGenerator)
      .then((data) => {
        setState({ data, error: null, isLoading: false });
        return data;
      })
      .catch((error) => {
        promise.cancel();

        if (error.name === 'AbortError') {
          return;
        }
        setState({ data: null, error, isLoading: false });
      });

    return promise;
  }, dependencies);

  useEffect(() => abortFetch, dependencies);

  return { ...state, execution };
};