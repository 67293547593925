// This hook should not be used directly. Please consider using "useDynamicThemeContext"
import { useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'underscore';
import { DescriptionElementClassName, NameElementClassName, BestDocumentHeight } from '@/constants';
import { useTheme } from 'react-jss';

const calculateHeight = className => Math.max(..._.map(document.querySelectorAll(`.${className}`), ele => ele.offsetHeight), 0);

const calculateTheme = () => {
  const nameHeight = calculateHeight(NameElementClassName);
  const descriptionHeight = calculateHeight(DescriptionElementClassName);

  return {
    nameHeight,
    descriptionHeight,
  };
};

const resize = (fullWidth) => {
  const currentHeight = document.documentElement.clientHeight;
  const currentWidth = document.documentElement.clientWidth;

  const bodyNeedsToScale = currentHeight / BestDocumentHeight;

  const fullWidthAfterScale = fullWidth * bodyNeedsToScale;

  const originalBodyWidth = fullWidthAfterScale < currentWidth ? fullWidth : currentWidth / bodyNeedsToScale;

  const afterScaleBodyWidth = originalBodyWidth * bodyNeedsToScale;
  const translateX = (currentWidth - afterScaleBodyWidth) / 2;

  document.body.style.transform = `translate3D(${translateX}px, 0, 0) scale(${bodyNeedsToScale})`;
  document.body.style.height = `${BestDocumentHeight}px`;
  document.body.style.width = `${originalBodyWidth}px`;
};

export const useDynamicTheme = () => {
  const [dynamicTheme, setDynamicTheme] = useState(() => calculateTheme());
  const { fullWidth } = useTheme();
  const [fontLoaded, setFontLoaded] = useState(false);

  const triggerCalculateTheme = useCallback(() => setDynamicTheme(calculateTheme()), []);

  useEffect(() => {
    const resizeHandler = () => resize(fullWidth);
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [fullWidth]);

  useEffect(() => {
    document.fonts.onloadingdone = () => {
      triggerCalculateTheme();
      setFontLoaded(true);
    };
  }, [triggerCalculateTheme]);

  return useMemo(() => ({
    ...dynamicTheme,
    fontLoaded,
    triggerCalculateTheme,
  }), [dynamicTheme, fontLoaded, triggerCalculateTheme]);
};
