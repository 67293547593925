export const getFooterStyles = () => ({
  container: {
    width: '100%',
    backgroundColor: '#1d1d1d',
    height: 218,
    paddingTop: 36,
    display: 'flex',
  },
  nav: {
    flex: 1,
    height: '100%',
    backgroundColor: '#242424',
    '&:hover': {
      backgroundColor: '#00a075',
      cursor: 'pointer',
    },
    '&:hover .colorWhite': {
      color: 'white',
    },
    '&:active .colorBlue': {
      color: '#00a2ff',
    },
  },
  leftNavContent: {
    width: 540,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginLeft: 'auto',
  },
  rightNavContent: {
    width: 540,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginRight: 'auto',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  leftNav: {
    justifyContent: 'flex-start',
  },
  leftIcon: {
    marginRight: 26,
  },
  rightNav: {
    justifyContent: 'flex-end',
  },
  rightIcon: {
    marginLeft: 26,
  },
  navContent: {
    color: '#888888',
  },
  guide: {
    fontFamily: 'Ruda-Regular',
    fontSize: 15,
    textTransform: 'uppercase',
  },
  name: {
    fontSize: 25,
    fontFamily: 'Ruda-ExtraBold',
    textTransform: 'uppercase',
  },
  borderRight: {
    borderRight: '1px solid #1d1d1d',
  },
});
