import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from '@/i18n';
import { useDefaultStyles } from '@/hooks';
import { useRouterContext, useWorkContext } from '@/context';
import { SimpleNameI18nSuffix } from '@/constants';
import { DirectoryItem } from './directory-item';
import { WorkList } from './work-list';
import { WorkDirectory } from './work-directory';
import { getPageDirectoryStyles } from './styles';

export const PageDirectory = ({
  className,
}) => {
  const { selectedWork, setHoveredWork } = useWorkContext();
  const { isAboutPage, isWorkPage, toWorkPage, toMainPage, toAboutPage } = useRouterContext();
  const subDirectory = useMemo(() => selectedWork
    ? <WorkDirectory work={selectedWork} />
    : <WorkList
        onWorkClick={toWorkPage}
        onWorkMouseEnter={setHoveredWork}
        onWorkMouseLeave={() => setHoveredWork(null)}
      />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [selectedWork]);
  const classes = useDefaultStyles(getPageDirectoryStyles);

  return (
    <div className={className}>
      <DirectoryItem
        title={i18n.getString('PageDirectory_Work')}
        titleSuffix={isWorkPage && selectedWork ? i18n.getString(selectedWork.name + SimpleNameI18nSuffix) : null}
        subDirectory={!isAboutPage ? subDirectory : null}
        onClick={toMainPage}
      />
      <DirectoryItem
        className={classes.directoryItem}
        title={i18n.getString('PageDirectory_About')}
        onClick={toAboutPage}
        link="/about"
      />
      <DirectoryItem
        className={classes.directoryItem}
        title={i18n.getString('PageDirectory_Resume')}
        onClick={() => window.open(window.PageConfig.ResumeLink)}
      />
      <DirectoryItem className={classes.directoryItem} title={i18n.getString('PageDirectory_Skill')} disabled />
      <DirectoryItem className={classes.directoryItem} title={i18n.getString('PageDirectory_SwitchLanguage')} disabled />
    </div>
  );
};

PageDirectory.propTypes = {
  className: PropTypes.string,
};

PageDirectory.defaultProps = {
  className: null,
};
