export const getWorkListStyles = () => ({
  changeViewMode: {
    color: 'rgba(255, 255, 255, 0.5)',
  },
  suffix: {
    '&:hover': {
      '& .hoverWhite': {
        color: 'white !important',
      },
      '&:before': {
        display: 'none',
      },
    },
  },
});
