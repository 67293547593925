export const getOperationsStyles = () => ({
  container: {
    position: 'fixed',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    width: 57,
    right: 0,
    bottom: 0,
  },
  btn: {
    display: 'flex',
    width: 57,
    height: 57,
    marginTop: 2,
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(30px)',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  btnImg: {
    width: 22,
    display: 'block',
  },
});
