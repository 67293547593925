/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRequestCallback } from './use-request-callback';

export const useRequest = (requestGenerator, dependencies = []) => {
  const { execution, ...rest } = useRequestCallback(requestGenerator, dependencies);

  useEffect(() => {
    execution();
  }, [...dependencies, execution]);

  return rest;
};
