export const getWorkWrapperStyles = ({ smallWorkHeight, workPadding }) => ({
  workWrapper: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    transition: 'transform .4s',
    willChange: 'transform',
    transform: ({
      extraWorksCount,
      isMainWorkAtTop,
      isMainWorkAtBottom,
      isMainWorkAtMiddle,
      isDefaultView,
    }) => {
      if (isDefaultView) {
        return null;
      }

      if (isMainWorkAtTop) {
        return `translateY(${extraWorksCount * (smallWorkHeight + workPadding)}px)`;
      } else if (isMainWorkAtBottom) {
        return `translateY(-${extraWorksCount * (smallWorkHeight + workPadding)}px)`;
      } else if (isMainWorkAtMiddle) {
        return `translateY(-${2 * (smallWorkHeight + workPadding)}px)`;
      } else {
        return null;
      }
    },
  },
  workContent: {
    width: '100%',
    height: '100%',
    transition: 'transform .4s',
    willChange: 'transform',
    transform: ({
      isMainWorkAtMiddle,
      isDefaultView,
    }) => (!isDefaultView && isMainWorkAtMiddle ? `translateY(${2 * (smallWorkHeight + workPadding)}px)` : null),
  },
  workInformationWrapper: ({ isMainWorkAtBottom, extraWorksCount, isDefaultView }) => ({
    position: 'relative',
    zIndex: 8,
    transition: 'transform .4s',
    transform: (isMainWorkAtBottom && !isDefaultView)
      ? `translate3D(0, ${extraWorksCount * (smallWorkHeight + workPadding)}px, 2px)`
      : 'translateZ(2px)',
  }),
});
