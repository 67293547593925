import React from 'react';
import PropTypes from 'prop-types';
import { useDefaultStyles } from '@/hooks';
import { getDirectoryItemStyles } from './styles';
import { Link } from 'react-router-dom';

export const DirectoryItem = ({
  className,
  title,
  titleSuffix,
  subDirectory,
  onClick,
  link,
  disabled,
}) => {
  const classes = useDefaultStyles(getDirectoryItemStyles, { disabled, clickable: !!onClick });

  return (
    <div className={className}>
      <div className={classes.titleContainer}>
        { link
          ? <Link className={classes.title} to={link}>{title}</Link> 
          : <span className={classes.title} onClick={onClick}>{title}</span>
        }
        {
          titleSuffix && <span className={classes.suffix}>{titleSuffix}</span>
        }
      </div>
      {
        subDirectory && <div className={classes.subDirectoryContainer}>{subDirectory}</div>
      }
    </div>
  );
};

DirectoryItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  titleSuffix: PropTypes.string,
  subDirectory: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  link: PropTypes.string,
};

DirectoryItem.defaultProps = {
  className: null,
  titleSuffix: null,
  subDirectory: null,
  onClick: null,
  disabled: false,
  link: null,
};
