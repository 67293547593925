export const getIndexStyles = ({ directoryWidth }) => ({
  app: {
    display: 'flex',
    height: '100%',
  },
  pageDirectory: {
    width: directoryWidth,
    flex: 'none',
    padding: [60, 44],
    boxSizing: 'border-box',
  },
  content: {
    flex: '1 0 auto',
    position: 'relative',
  },
});
