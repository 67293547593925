import React, {
    createContext,
    useContext,
    useCallback,
    useMemo,
  } from 'react';
  import { useLocation, useHistory } from 'react-router-dom';
  import PropTypes from 'prop-types';
  import { useWorkContext } from '../index';
  
  export const RouterContext = createContext({});
  
  export const RouterContextProvider = ({ children }) => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { setSelectedWork } = useWorkContext();
    
    const isAboutPage = useMemo(() => pathname === '/about', [pathname]);
    const isWorkPage = useMemo(() => pathname.startsWith('/work'), [pathname]);
  
    const toPage = useCallback((path, work = null) => {
      setSelectedWork(work);
      history.push(path);
    }, [setSelectedWork, history]);

    const toMainPage = useCallback(() => toPage('/'), [toPage]);
    const toAboutPage = useCallback(() => toPage('/about'), [toPage]);
    const toWorkPage = useCallback(work => toPage(`/work/${work.alias}`, work), [toPage]);
  
    return (
      <RouterContext.Provider value={{
        isAboutPage,
        isWorkPage,
        toMainPage,
        toAboutPage,
        toWorkPage,
      }}>
        {children}
      </RouterContext.Provider>
    );
  };
  
  export const useRouterContext = () => useContext(RouterContext);
  
  RouterContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  