import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import i18n from '@/i18n';
import { useDefaultStyles } from '@/hooks';
import {
  PeopleI18nSuffix,
  DesignForI18nSuffix,
  DurationI18nSuffix,
  LongDescriptionI18nSuffix,
  MyRoleI18nSuffix, 
  NameI18nSuffix,
  SloganI18nSuffix,
} from '@/constants';
import { Label } from './label';
import { Credit } from './credit';
import { getInformationStyles } from './styles';

export const Information = ({
  work,
}) => {
  const classes = useDefaultStyles(getInformationStyles, { work });
  const renderContent = suffix => i18n.getString(work.name + suffix).split('||')
      .map(content => <div key={content} dangerouslySetInnerHTML={{ __html: content }} />);

  return (
    <div>
      <div className={classes.bg}>
        <div className={classes.mask} />
        <div className={classes.bgImg} />
        { work.credit && <Credit className={classes.credit} content={i18n.getString(work.credit)} /> }
      </div>
      <div className={classes.content}>
        <div className={classes.name}>
          {renderContent(NameI18nSuffix)}
        </div>
        <div className={classes.slogan}>
          {renderContent(SloganI18nSuffix)}
        </div>
        <div className={classes.description}>
          {renderContent(LongDescriptionI18nSuffix)}
        </div>
        <div className={classes.details}>
          <div className={classes.textPanel}>
            <div className={classes.textWrapper}>
              <Label className={classes.label} label={i18n.getString('DesignFor')}>
                {renderContent(DesignForI18nSuffix)}
              </Label>
              <Label className={classes.label} label={i18n.getString('Duration')}>
                {renderContent(DurationI18nSuffix)}
              </Label>
              <Label className={classes.label} label={i18n.getString('MyRole')}>
                {renderContent(MyRoleI18nSuffix)}
              </Label>
              <Label className={classes.label} label={i18n.getString('People')}>
                {renderContent(PeopleI18nSuffix)}
              </Label>
            </div>
          </div>
          <div className={classes.imagePane}>
            <Label className={classes.imageLabel} label={i18n.getString(work.freeField.name)} contentClass={classes.imageLabelContent}>
              <div className={classes.collaborator}>
              {
                _.map(work.freeField.content, src => <img key={src} src={src} className={classes.collaboratorLogo} alt="freeFieldContent" />)
              }
              </div>
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};

Information.propTypes = {
  work: PropTypes.shape({
    name: PropTypes.string.isRequired,
    credit: PropTypes.string,
    freeField: PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
};
