export const getWorkSwiperStyles = () => ({
  button: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 999,
  },
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: '#2d2d2d',
    zIndex: 2,
  },
});
