import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'underscore';
import { useDefaultStyles } from '@/hooks';
import { SimpleNameI18nSuffix, WorkContainerId } from '@/constants';
import { usePageContext, useWorkContext } from '@/context';
import i18n from '@/i18n';
import { getWorkStyles } from './styles';
import { WorkContent } from './work-content';

const titleCase = str => str.toLowerCase().replace(/(\s|^)[a-z]/g, match =>  match.toUpperCase());

export const Work = () => {
  const { works } = usePageContext();
  const classes = useDefaultStyles(getWorkStyles);
  const { alias } = useParams();
  const { setSelectedWork } = useWorkContext();
  const work = _.find(works, { alias });

  useEffect(() => {
    const container = document.getElementById(WorkContainerId);
    setSelectedWork(work);
    container && container.scrollTo(0, 0);
    document.title = titleCase(i18n.getString(work.name + SimpleNameI18nSuffix)) + ' - ' + window.PageConfig.PageTitle;
    
    return () => setSelectedWork(null);
  }, [work, setSelectedWork]);

  return (
    <div className={classnames(classes.work, 'scrolling')} id={WorkContainerId}>
      <WorkContent work={work} className={classes.workContent} />
    </div>
  );
};
