import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDefaultStyles } from '@/hooks';
import { LoadContent } from '@/components';
import i18n from '@/i18n';
import { getAboutViewStyles } from './styles';
import { Operations } from './operations';

export const About = () => {
  const classes = useDefaultStyles(getAboutViewStyles);

  useEffect(() => {
    document.title = i18n.getString('PageDirectory_About') + ' - ' + window.PageConfig.PageTitle;
  }, []);

  return (
    <div className={classnames(classes.container, 'scrolling')}>
      <Operations />
      <LoadContent path="./tpl/about.tpl" />
    </div>
  );
};