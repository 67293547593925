import React, {
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';
import PropTypes from 'prop-types';
import { launch } from './launch';

export const PageContext = createContext({});

export const PageContextProvider = ({ children }) => {
  const [context, setContext] = useState(null);

  useEffect(() => {
    launch().then(setContext);
  }, []);

  return (
    <PageContext.Provider value={context}>
      {context && children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);

PageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
