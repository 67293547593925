export const PageMode = {
  defaultView: 0,
  shrinkView: 1,
};

export const SimpleNameI18nSuffix = '_SimpleName';

export const NameI18nSuffix = '_Name';

export const DescriptionI18nSuffix = '_Description';

export const LongDescriptionI18nSuffix = '_LongDescription';

export const SubtitleI18nSuffix = '_Subtitle';

export const SloganI18nSuffix = '_Slogan';

export const DesignForI18nSuffix = '_DesignFor';

export const MyRoleI18nSuffix = '_MyRole';

export const DurationI18nSuffix = '_Duration';

export const PeopleI18nSuffix = '_People';

export const CollaboratorI18nSuffix = '_Collaborator';

export const NameElementClassName = 'WorkSwiper_Work_Name';

export const DescriptionElementClassName = 'WorkSwiper_Work_Description';

export const WorkContainerId = 'work-container';

export const DataTargetId = 'data-target-id';

export const BestDocumentHeight = 969;

export const WorkPadding = 10;

export const DirectoryMinWidth = 311;