import React from 'react';
import { Operations as BaseOperations } from '@/components';
import { useRouterContext } from '@/context';
import backImagePath from '@/assets/images/back.png';

export const Operations = () => {
  const { toMainPage } = useRouterContext();

  return (
    <BaseOperations
      operations={[{
        id: 'back',
        img: backImagePath,
        onClick: () => toMainPage(),
      }]}
    />
  );
};
