export const getInformationStyles = ({ documentHeight }) => ({
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: documentHeight,
  },
  mask: ({ work }) => work && ({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    ...work.maskStyle,
  }),
  credit: {
    position: 'absolute',
    zIndex: 5,
    left: 20,
    bottom: 20,
  },
  bgImg: ({ work }) => work && ({
    backgroundImage: `url(${work.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  }),
  content: {
    zIndex: 3,
    width: 952,
    height: documentHeight,
    color: 'white',
    textAlign: 'left',
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  name: {
    fontSize: '20px',
    lineHeight: 1.43,
    textTransform: 'uppercase',
  },
  slogan: {
    fontSize: 35,
    lineHeight: 1.43,
    fontFamily: 'Cambon',
    margin: [10, 0, 30, 0],
  },
  description: {
    fontSize: '20px',
    // fontFamily: 'Fira Sans',
    fontFamily: 'Open Sans',
    fontWeight: '300',
    lineHeight: 1.43,
    marginBottom: 30,
  },
  details: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
  },
  textPanel: {
    flex: '1',
    display: 'flex',
  },
  textWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imagePanel: {
    flex: 'none',
  },
  label: {
    marginTop: 30,
    flex: '0 0 50%',
    '& a': {
      color: 'white',
      '&:hover': {
        color: '#969696',
      },
    },
  },
  imageLabel: {
    marginTop: 30,
  },
  imageLabelContent: {
    width: 'auto !important',
  },
  collaborator: {
    marginTop: 15,
  },
  collaboratorLogo: {
    height: 39,
    display: 'block',
    marginBottom: 10,
  },
});
