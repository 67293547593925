const dictionary = { zh: window.zh, en: window.en };
const env = 'en';

const i18n = {
  getString: (key) => {
    const string = dictionary[env][key];
    if (string) {
      return string;
    }

    console.warn(`Missing key: ${env} - ${key}`);
    return key;
  },
};

window.i18n = i18n;

export default i18n;
