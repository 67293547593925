import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@/i18n';
import { SubDirectory } from './sub-directory';
import { DataTargetId, WorkContainerId } from '@/constants';

export const WorkDirectory = ({
  work,
}) => {
  const onItemClick = (item, e) => {
    const targetId = e.target.getAttribute(DataTargetId);
    const targetDOM = document.getElementById(targetId);

    if (!targetDOM) {
      return;
    }

    document.getElementById(WorkContainerId).scrollTo({
      top: targetDOM.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <SubDirectory
      items={work.directory}
      onItemClick={onItemClick}
      keyGenerator={item => item.name}
      textGenerator={item => i18n.getString(item.name)}
      extraDataGenerator={(item, index) => ({
        [DataTargetId]: `index${index + 1}`,
      })}
    />
  );
};

WorkDirectory.propTypes = {
  work: PropTypes.shape({
    alias: PropTypes.string,
    name: PropTypes.string,
    directory: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
