import React, {
  useContext,
  createContext,
} from 'react';
import PropTypes from 'prop-types';
import { useDynamicTheme } from '@/hooks';

export const DynamicThemeContext = createContext({});

export const DynamicThemeContextProvider = ({ children }) => {
  const dynamicTheme = useDynamicTheme();

  return (
    <DynamicThemeContext.Provider value={dynamicTheme}>
      {children}
    </DynamicThemeContext.Provider>
  );
};

export const useDynamicThemeContext = () => useContext(DynamicThemeContext);

DynamicThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
