import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import i18n from '@/i18n';
import { useDefaultStyles } from '@/hooks';
import { useDynamicThemeContext, useViewModeContext, useWorkContext } from '@/context';
import {
  NameI18nSuffix,
  DescriptionI18nSuffix,
  SubtitleI18nSuffix,
  DescriptionElementClassName,
  NameElementClassName,
} from '@/constants';
import { getWorkStyles } from './styles';
import { WorkWrapper } from './work-wrapper';

export const Work = ({
  work,
  className,
  extraWorksCount,
  isMainWork,
  isMainWorkAtTop,
  isMainWorkAtMiddle,
  isMainWorkAtBottom,
}) => {
  const [shrinkNameHeight, setShrinkNameHeight] = useState(0);
  const { isDefaultView } = useViewModeContext();
  const { hoveredWork } = useWorkContext();
  const { fontLoaded } = useDynamicThemeContext();
  const classes = useDefaultStyles(getWorkStyles, {
    maskStyle: work.maskStyle,
    imageStyle: isDefaultView ? work.imageStyle : work.shrinkImageStyle,
    shrinkNameHeight,
    extraWorksCount,
    isMainWork,
    isMainWorkAtTop,
    isDefaultView,
  });
  const shrinkNameElementRef = useRef();
  const isHoveredWork = hoveredWork && hoveredWork.alias === work.alias;

  useEffect(() => {
    setShrinkNameHeight(shrinkNameElementRef.current.offsetHeight);
  }, [fontLoaded]);

  const workInformation = (
    <div className={classes.workInformation}>
      <img className={classes.logo} src={work.logo} alt="logo" />
      { isMainWork && (
        <div className={classnames(classes.name, classes.defaultName)}>
          <div className={NameElementClassName}>{i18n.getString(work.name + NameI18nSuffix)}</div>
        </div>)
      }
      <div className={classnames(classes.name, classes.shrinkName)}>
        <div ref={shrinkNameElementRef}>{i18n.getString(work.name + NameI18nSuffix)}</div>
      </div>
      { isMainWork && (
          <div className={classes.description}>
            <div className={classnames(classes.descriptionText, DescriptionElementClassName)}>{i18n.getString(work.name + DescriptionI18nSuffix)}</div>
          </div>)
      }
      <div className={classes.subtitle}>{i18n.getString(work.name + SubtitleI18nSuffix)}</div>
    </div>
  );

  return (
    <Link to={`/work/${work.alias}`} className={classnames(className, classes.work, isHoveredWork && classes.hovered)}>
      <WorkWrapper
        extraWorksCount={extraWorksCount}
        isMainWorkAtTop={isMainWorkAtTop}
        isMainWorkAtMiddle={isMainWorkAtMiddle}
        isMainWorkAtBottom={isMainWorkAtBottom}
        workInformation={workInformation}
        isMainWork={isMainWork}
      >
        <div className={classnames(classes.workMask, 'hoverHidden')} />
        <div className={classnames(classes.workImageContainer)}>
          <img
            alt="work"
            className={classes.workImage}
            src={work.image}
          />
        </div>
      </WorkWrapper>
    </Link>
  );
};

Work.propTypes = {
  work: PropTypes.shape({
    alias: PropTypes.string,
    name: PropTypes.string,
    subtitle: PropTypes.string,
    logo: PropTypes.string,
    image: PropTypes.string,
    imageStyle: PropTypes.shape({}),
    shrinkImageStyle: PropTypes.shape({}),
    maskStyle: PropTypes.shape({}),
  }).isRequired,
  className: PropTypes.string,
  extraWorksCount: PropTypes.number,
  isMainWorkAtTop: PropTypes.bool,
  isMainWorkAtMiddle: PropTypes.bool,
  isMainWorkAtBottom: PropTypes.bool,
  isMainWork: PropTypes.bool,
};

Work.defaultProps = {
  className: null,
  extraWorksCount: 0,
  isMainWorkAtTop: false,
  isMainWorkAtMiddle: false,
  isMainWorkAtBottom: false,
  isMainWork: false,
};
