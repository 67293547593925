import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDefaultStyles } from "@/hooks";
import { getLabelStyles } from "./styles";

export const Label = ({ className, contentClass, label, children }) => {
  const classes = useDefaultStyles(getLabelStyles);

  return (
    <div className={classnames(className, classes.label)}>
      <div className={classnames(classes.content, contentClass)}>
        <div className={classes.key}>{label}</div>
        <div className={classes.value}>{children}</div>
      </div>
    </div>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  children: PropTypes.node,
  contentClass: PropTypes.string,
};

Label.defaultProps = {
  className: null,
  label: null,
  children: null,
  contentClass: null,
};
