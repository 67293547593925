export const getWorkStyles = ({ documentHeight }) => ({
  work: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    zIndex: 2,
    height: documentHeight,
    overflow: 'auto',
    backgroundColor: '#1d1d1d',
    '&::-webkit-scrollbar': {
      width: '0',
    },
  },
  workContent: {
    minWidth: 1090,
    position: 'relative',
  },
});
