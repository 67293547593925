import React from 'react';
import PropTypes from 'prop-types';
import { useRequest } from '@/hooks/use-request';

export const LoadContent = ({ path }) => {
  const { data } = useRequest(request => request(path), [path]);
  
  return (
    <div dangerouslySetInnerHTML={{ __html: data }}></div>
  );
};

LoadContent.propTypes = {
  path: PropTypes.string.isRequired,
};
