export const getSubDirectoryStyles = () => ({
  item: {
    display: 'block',
    border: 'none',
    backgroundColor: 'transparent',
    color: '#fff',
    fontFamily: 'Ruda-Regular',
    fontSize: 15,
    width: '100%',
    textAlign: 'left',
    outline: 'none',
    cursor: 'pointer',
    padding: '6px 0 6px 11px',
    position: 'relative',
    textTransform: 'uppercase',
    '&:before': {
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: 1,
      height: 16,
      content: '""',
      background: '#fff',
      opacity: 0,
    },
    '&:hover': {
      '&:before': {
        opacity: 1,
      },
    },
  },
});
