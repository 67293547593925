import _ from 'underscore';

function insertAfter(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

const circleSize = 95;

class BoneSwiper extends HTMLElement {
  constructor() {
    super();
    this.play = true;
    this.currentTime = 0;
    this.parseSwiper();
  }

  setCurrentTime(value) {
    this.currentTime = value;
    this.swiperContainer.querySelector('.bone-swiper-countdown circle.inner').style.strokeDashoffset = -circleSize + this.currentTime / this.duration * circleSize + 'px';
  }

  parseSwiper() {
    this.id = this.getAttribute('id');
    this.swiperId = `${this.id}-swiper`;
    this.swiperClassName = `${this.id}-swiper-c`;
    this.duration = Number(this.getAttribute('duration')) || 8000;
    this.swiperItems = _.map(this.querySelectorAll('item'), this.parseItem);

    this.validate();
  }

  parseItem(item) {
    return {
      src: item.getAttribute('src'),
      title: item.getAttribute('title'),
      description: item.getAttribute('description'),
    };
  }

  validate() {
    this.valid = false;

    if (_.isEmpty(this.id) || this.id === 'null') {
      console.error('bone-swiper: The id attribute is required! Please set a unique id.', this);
      return;
    }

    if (document.querySelectorAll(`#${this.id}`).length > 1) {
      console.error(`bone-swiper: Please set a unique id for #${this.id}.`, this);
      return;
    }

    this.valid = true;
  }

  stop = () => {
    this.play = false;
  }

  start = () => {
    this.play = true;
  }

  stopLoop = () => {
    this.setCurrentTime(80);
    clearInterval(this.intervalId);
  }

  startLoop = () => {
    this.intervalId = setInterval(() => {
      if (!this.play) {
        return;
      }

      this.setCurrentTime(this.currentTime + 50);
      if (this.currentTime >= this.duration) {
        clearInterval(this.intervalId);

        const nextActiveIndex = (this.swiperInstance.activeIndex + 1) % this.swiperItems.length;
        this.swiperInstance.slideTo(nextActiveIndex);
        this.setCurrentTime(80);
      }
    }, 50);
  }

  listener() {
    this.swiperInstanceElement.addEventListener('mouseenter', this.stop);
    this.swiperInstanceElement.addEventListener('mouseleave', this.start);
  }

  connectedCallback() {
    const fragment = document.createDocumentFragment();
    this.swiperContainer = document.createElement('div');

    this.swiperContainer.setAttribute('id', this.swiperId);
    this.swiperContainer.classList.add(this.swiperClassName, 'bone-swiper-container');

    this.swiperContainer.innerHTML = `
      <div class="bone-swiper-titles"></div>
      <div class="bone-swiper-countdown">
        <svg>
          <circle r="15" cx="20" cy="20" class="border"></circle>
          <circle r="15" cx="20" cy="20" class="inner"></circle>
        </svg>
      </div>
      <div class="bone-swiper-instance">
        <div class="swiper-wrapper">
          ${this.swiperItems.map(({ src, description }) => `
            <div class="swiper-slide">
              <div class="swiper-slide-image" style="background-image: url(${src})"></div>
              <div class="swiper-slide-description">
                <div>${description}</div>
              </div>
            </div>
          `).join('')}
        </div>
      </div>`;

    fragment.appendChild(this.swiperContainer);
    insertAfter(fragment, this);

    this.swiperInstanceSelector = `#${this.swiperId} .bone-swiper-instance`;
    this.swiperInstance = new window.Swiper(this.swiperInstanceSelector, {
      autoplay: false,
      delay: this.duration,
      pagination: {
        el: `#${this.swiperId} .bone-swiper-titles`,
        clickable: true,
        bulletActiveClass: 'bone-swiper-title-active',
        renderBullet: (index, className) => {
          return `<div class="bone-swiper-title ${className}">${this.swiperItems[index].title}</div>`;
        },
      },
      on: {
        init: () => this.startLoop(),
        slideChangeTransitionStart: () => this.stopLoop(),
        slideChangeTransitionEnd: () => this.startLoop(),
      },
    });
    this.swiperInstanceElement = document.querySelector(this.swiperInstanceSelector);
    this.listener();
  }

  disconnectedCallback() {
    this.stopLoop();
    this.swiperInstance && this.swiperInstance.destroy();
  }
}

window.customElements.define('bone-swiper', BoneSwiper);