import React, {
    createContext,
    useState,
    useContext,
  } from 'react';
  import PropTypes from 'prop-types';
  
  export const WorkContext = createContext({});
  
  export const WorkContextProvider = ({ children }) => {
    const [hoveredWork, setHoveredWork] = useState(null);
    const [selectedWork, setSelectedWork] = useState(null);

    return (
      <WorkContext.Provider value={{
        hoveredWork,
        setHoveredWork,
        selectedWork,
        setSelectedWork,
      }}>
        {children}
      </WorkContext.Provider>
    );
  };
  
  export const useWorkContext = () => useContext(WorkContext);
  
  WorkContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  