import PinImagePath from '@/assets/images/pin.png';

export const getCreditStyles = () => ({
  credit: {
    '&:hover': {
      '& .hoverDisplay': {
        opacity: 1,
        pointerEvents: 'auto',
      },
    },
  },
  img: {
    width: 40,
    height: 40,
    backgroundImage: `url(${PinImagePath})`,
    backgroundSize: '100% auto',
  },
  contentWrapper: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    paddingBottom: 10,
    transform: 'translateY(-100%)',
  },
  content: {
    color: 'white',
    // fontFamily: 'Fira Sans',
    fontFamily: 'Open Sans',
    fontWeight: '300',
    fontSize: 15,
    height: 40,
    background: 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(30px)',
    padding: [0, 10],
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    borderRadius: 5,
  },
});
