import { createUseStyles, useTheme } from 'react-jss';
import _ from 'underscore';
import { useDynamicThemeContext } from '@/context';

export const useDefaultStyles = (getStyles = _.noop, props = {}) => {
  const theme = useTheme();
  const dynamicTheme = useDynamicThemeContext();
  const useStyles = createUseStyles(getStyles(theme));
  return useStyles({ ...props, ...dynamicTheme });
};
