import { useMemo } from 'react';
import _ from 'underscore';
import { usePageContext } from '@/context';

export const useGroupedWorks = () => {
  const { mainWorks, works, position } = usePageContext();

  return useMemo(() => _.map(position[0], (uselessVariable, index) => {
    const columnWorks = _.chain([
      position[0][index],
      position[1][index],
      position[2][index],
    ]).compact()
      .flatten()
      .map(alias => _.find(works, { alias }))
      .value();

    const mainWork = mainWorks[index];
    const mainWorkIndex = _.findIndex(columnWorks, { alias: mainWork.alias });

    return {
      aboveWork: columnWorks.slice(0, mainWorkIndex),
      work: mainWork,
      belowWork: columnWorks.slice(mainWorkIndex + 1),
      columnWorks,
    };
  }), [mainWorks, works, position]);
};
