import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDefaultStyles } from '@/hooks';
import { getCreditStyles } from './styles';

export const Credit = ({ content, className }) => {
  const classes = useDefaultStyles(getCreditStyles);

  return content && (
    <div className={classnames(classes.credit, className)}>
      <div className={classnames(classes.contentWrapper, 'hoverDisplay')}>
        <div className={classes.content}>{content}</div>
      </div>
      <div className={classes.img} />
    </div>
  );
};

Credit.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

Credit.defaultProps = {
  content: null,
  className: null,
};
