import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';
import { WorkContainerId } from '@/constants';
import { LoadContent } from '@/components';
import { Information } from './information';
import { Operations } from './operations';
import { Footer } from './footer';

export const WorkContent = ({ work, className }) => {
  const [showBackTop, setShowBackTop] = useState(false);
  const { documentHeight } = useTheme();

  useEffect(() => {
    const workContainer = document.getElementById(WorkContainerId);

    const onScrollHandler = () => {
      setShowBackTop(workContainer.scrollTop > documentHeight);
    };

    onScrollHandler();
    workContainer.addEventListener('scroll', onScrollHandler);
    return () => workContainer.removeEventListener('scroll', onScrollHandler);
  }, [documentHeight]);

  return (
    <div className={className}>
      <Information work={work} />
      {
        work.contentPath && <LoadContent path={work.contentPath} />
      }
      <Footer work={work} />
      <Operations showBackTop={showBackTop} />
    </div>
  );
};

WorkContent.propTypes = {
  work: PropTypes.shape({
    contentPath: PropTypes.string,
  }).isRequired,
  className: PropTypes.string.isRequired,
};
