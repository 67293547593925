import React from 'react';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { PageDirectory } from '@/components';
import { useDefaultStyles } from '@/hooks';
import { About } from './about';
import { Work } from './work';
import { WorkSwiper } from './work-swiper';
import { getIndexStyles } from './styles';

export function Index() {
  const classes = useDefaultStyles(getIndexStyles);
  const location = useLocation();

  return (
    <div className={classes.app}>
      <PageDirectory className={classes.pageDirectory} />
      <div className={classes.content}>
        <TransitionGroup>
          <CSSTransition key={location.pathname} classNames="fade" timeout={400}>
            <Switch location={location}>
              <Route path="/work/:alias"><Work /></Route>
              <Route path="/about"><About /></Route>
              <Route path="*"><WorkSwiper /></Route>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}
