import React, { useMemo } from 'react';
import {
  BrowserRouter,
  HashRouter,
} from "react-router-dom";
import { ThemeProvider } from 'react-jss';
import { Index } from '@/pages';
import { useStaticTheme } from '@/hooks';
import { DynamicThemeContextProvider, PageContextProvider, ViewModeContextProvider, RouterContextProvider, WorkContextProvider } from '@/context';

import './css/base.css';
import './css/bone-swiper.css';
import './css/fonts.css';

const Inner = () => {
  const staticTheme = useStaticTheme();
  const Router = useMemo(() => process.env.REACT_APP_PAGE_ENV === 'github' ? HashRouter : BrowserRouter, []);

  return (
    <ThemeProvider theme={staticTheme}>
      <DynamicThemeContextProvider>
        <WorkContextProvider>
          <ViewModeContextProvider>
            <Router>
              <RouterContextProvider>
                <Index />
              </RouterContextProvider>
            </Router>
          </ViewModeContextProvider>
        </WorkContextProvider>
      </DynamicThemeContextProvider>
    </ThemeProvider>
  );
};

export const App = () => (
  <PageContextProvider>
    <Inner />
  </PageContextProvider>
);
