import { useMemo } from 'react';
import { useTheme } from 'react-jss';
import { useDynamicThemeContext, usePageContext } from '@/context';
import { useSwiper } from './use-swiper';

export const useWorkSwiper = ({
  selector,
}) => {
  const { mainWorks } = usePageContext();
  const { workPadding } = useTheme();
  const { triggerCalculateTheme } = useDynamicThemeContext();

  const options = useMemo(() => ({
    slidesPerView: 'auto',
    loopedSlides: mainWorks.length,
    spaceBetween: workPadding,
    mousewheel: true,
    updateOnWindowResize: false,
    on: {
      init: () => triggerCalculateTheme(),
    },
  }), [mainWorks.length, workPadding, triggerCalculateTheme]);
  
  return useSwiper({ selector, options });
};
