import React, {
  createContext,
  useState,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { PageMode } from '@/constants';

export const ViewModeContext = createContext({});

export const ViewModeContextProvider = ({ children }) => {
  const [mode, setMode] = useState(PageMode.defaultView);

  const toShrinkView = useCallback(() => setMode(PageMode.shrinkView), []);
  const toDefaultView = useCallback(() => setMode(PageMode.defaultView), []);

  return (
    <ViewModeContext.Provider value={{
      mode,
      toShrinkView,
      toDefaultView,
      isDefaultView: mode === PageMode.defaultView,
    }}>
      {children}
    </ViewModeContext.Provider>
  );
};

export const useViewModeContext = () => useContext(ViewModeContext);

ViewModeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
