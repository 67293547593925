import _ from 'underscore';

export const launch = async () => {
  const allWorksPromises = window.PageContext.allWorkFiles.map(alias => fetch(`/works/${alias}.json`).then(data => data.json()));

  await Promise.all(allWorksPromises).then((data) => {
    const { position } = window.PageContext;
    const flattenedPosition = _.flatten(position, 1);
    const mainWorkAliases = _.filter(flattenedPosition, item => _.isArray(item)).map(item => item[0]);

    window.PageContext.works = data.slice(0);
    window.PageContext.mainWorks = _.filter(data.slice(0), (({ alias }) => _.contains(mainWorkAliases, alias)));
  });

  return window.PageContext;
};
