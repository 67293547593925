import React from 'react';
import PropTypes from 'prop-types';
import { useDefaultStyles } from '@/hooks';

import { getOperationsStyles } from './styles';

export const Operations = ({ operations }) => {
  const classes = useDefaultStyles(getOperationsStyles);

  return (
    <div className={classes.container}>
      {
        operations.map(({ id, img, onClick }) => (
          <button key={id} className={classes.btn} onClick={onClick}>
            <img className={classes.btnImg} src={img} alt="operation"/>
          </button>
        ))
      }
    </div>
  );
};

Operations.propTypes = {
  operations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    img: PropTypes.string,
    onClick: PropTypes.func,
  })),
};

Operations.defaultProps = {
  operations: [],
};
