import React from 'react';
import PropTypes from 'prop-types';
import { Operations as BaseOperations } from '@/components';
import { useViewModeContext } from '@/context';
import rightArrowImagePath from '@/assets/images/arrow_1.png';
import leftArrowImagePath from '@/assets/images/arrow_2.png';
import defaultViewImagePath from '@/assets/images/small.png';
import shrinkViewImagePath from '@/assets/images/big.png';

export const Operations = ({
  swiperInstance,
}) => {
  const { toShrinkView, toDefaultView, isDefaultView } = useViewModeContext();

  const onChangeViewModeButtonClick = () => isDefaultView ? toShrinkView() : toDefaultView();
  const slideNext = () => swiperInstance.slideNext();
  const slidePrev = () => swiperInstance.slidePrev();

  return swiperInstance && (
    <BaseOperations
      operations={[{
        id: 'right',
        img: rightArrowImagePath,
        onClick: slideNext,
      }, {
        id: 'left',
        img: leftArrowImagePath,
        onClick: slidePrev,
      }, {
        id: 'changeView',
        img: isDefaultView ? defaultViewImagePath : shrinkViewImagePath,
        onClick: onChangeViewModeButtonClick,
      }]}
    />
  );
};

Operations.propTypes = {
  swiperInstance: PropTypes.shape({
    slideNext: PropTypes.func,
    slidePrev: PropTypes.func,
  }),
};

Operations.defaultProps = {
  swiperInstance: null,
};
