import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Operations as BaseOperations } from '@/components';
import { useRouterContext } from '@/context';
import { WorkContainerId } from '@/constants';
import backImagePath from '@/assets/images/back.png';
import backTopImagePath from '@/assets/images/backtop.png';

export const Operations = ({ showBackTop }) => {
  const { toMainPage } = useRouterContext();

  const backTopOnClickHandler = useCallback(() => {
    document.getElementById(WorkContainerId).scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const operations = useMemo(() => _.compact([showBackTop && {
    id: 'backTop',
    img: backTopImagePath,
    onClick: backTopOnClickHandler,
  }, {
    id: 'back',
    img: backImagePath,
    onClick: () => toMainPage(),
  }]), [showBackTop, backTopOnClickHandler, toMainPage]);

  return (
    <BaseOperations operations={operations} />
  );
};

Operations.propTypes = {
  showBackTop: PropTypes.bool.isRequired,
};
