import React from 'react';
import PropTypes from 'prop-types';
import { useDefaultStyles } from '@/hooks';
import { useViewModeContext } from '@/context';
import { getWorkWrapperStyles } from './styles/work-wrapper';

export const WorkWrapper = ({
  children,
  extraWorksCount,
  isMainWorkAtTop,
  isMainWorkAtMiddle,
  isMainWorkAtBottom,
  isMainWork,
  workInformation,
}) => {
  const { isDefaultView } = useViewModeContext();
  const classes = useDefaultStyles(getWorkWrapperStyles, {
    extraWorksCount,
    isMainWorkAtTop,
    isMainWorkAtMiddle,
    isMainWorkAtBottom,
    isDefaultView,
    isMainWork,
  });

  return (
    <div className={classes.workWrapper}>
      <div className={classes.workContent}>
        <div className={classes.workInformationWrapper}>
          {workInformation}
        </div>
        {children}
      </div>
    </div>
  );
};

WorkWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  extraWorksCount: PropTypes.number,
  isMainWork: PropTypes.bool,
  isMainWorkAtTop: PropTypes.bool,
  isMainWorkAtMiddle: PropTypes.bool,
  isMainWorkAtBottom: PropTypes.bool,
  workInformation: PropTypes.node,
};

WorkWrapper.defaultProps = {
  className: null,
  children: null,
  extraWorksCount: 0,
  isMainWork: false,
  isMainWorkAtTop: false,
  isMainWorkAtMiddle: false,
  isMainWorkAtBottom: false,
  workInformation: null,
};

