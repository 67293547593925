import { useEffect, useState } from 'react';

export const useSwiper = ({
  selector,
  options,
}) => {
  const [swiperInstance, setSwiperInstance] = useState(null);

  useEffect(() => {
    const swiper = new window.Swiper(selector, options);

    const resize = () => {
      setTimeout(() => {
        swiper.update();
      }, 1000);
    };

    setSwiperInstance(swiper);
    window.addEventListener('resize', resize);
    return () => {
      swiper.destroy();
      window.removeEventListener('resize', resize);
    };
  }, [options, selector]);

  return swiperInstance;
};
