export const getColumnStyles = ({ workWidth, smallWorkHeight, workPadding, fullWorkHeight }) => ({
  swiperSlide: {
    color: 'white',
    boxSizing: 'border-box',
    width: workWidth,
  },
  columnWrapper: {
    transition: 'transform .4s',
    willChange: 'transform',
  },
  isMainWorkAtTop: ({ isDefaultView, extraWorksCount }) => ({
    transform: 'translateY(0)',
    ...!isDefaultView && {
      transform: `translateY(-${extraWorksCount * (smallWorkHeight + workPadding)}px)`,
    },
  }),
  isMainWorkAtMiddle: ({ isDefaultView }) => ({
    transform: `translateY(-${smallWorkHeight + workPadding}px)`,
    willChange: 'transform',
    '& .isBelowWork': {
      transform: `translateY(0)`,
    },
    ...!isDefaultView && {
      transform: 'translateY(0)',
      '& .isBelowWork': {
        transform: `translateY(-${2 * (smallWorkHeight + workPadding)}px)`,
      },
    },
  }),
  isMainWorkAtBottom: ({ extraWorksCount, isDefaultView }) => ({
    transform: `translateY(-${extraWorksCount * (smallWorkHeight + workPadding)}px)`,
    ...!isDefaultView && {
      transform: 'translateY(0)',
    },
  }),
  fullSize: {
    height: fullWorkHeight,
    width: workWidth,
  },
  smallSize: {
    width: workWidth,
    height: smallWorkHeight,
    marginBottom: ({ isSecond }) => isSecond ? 0 : workPadding,
    marginTop: ({ isSecond }) => isSecond ? workPadding : 0,
    '&.isBelowWork': {
      marginTop: workPadding,
      transition: 'transform .4s',
    },
    '&.isAboveWork': {
      marginBottom: workPadding,
    },
  },
});