import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDefaultStyles, useWorkSwiper } from '@/hooks';
import { Operations } from './operations';
import { getWorkSwiperStyles } from './styles';
import { SwiperPanel } from './swiper-panel';


export const WorkSwiper = ({
  className,
}) => {
  const classes = useDefaultStyles(getWorkSwiperStyles);
  const swiperInstance = useWorkSwiper({ selector: '.swiper-container' });

  useEffect(() => {
    document.title = window.PageConfig.PageTitle;
  }, []);

  return (
    <div className={classnames(className, classes.wrapper)}>
      <SwiperPanel className="swiper-container"/>
      <Operations swiperInstance={swiperInstance} />
    </div>
  );
};

WorkSwiper.propTypes = {
  className: PropTypes.string,
};

WorkSwiper.defaultProps = {
  className: null,
};
