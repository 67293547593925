export const getLabelStyles = () => ({
  content: {
    width: 245,
  },
  key: {
    fontSize: 15,
    lineHeight: 1.43,
  },
  value: {
    marginTop: 7,
    // fontFamily: 'Fira Sans',
    fontFamily: 'Open Sans',
    fontWeight: '300',
    fontSize: 15,
    lineHeight: 1.43,
  },
});
